import Head from "next/head";
import { useRouter } from "next/router";

const CustomMetas = ({ title, description, keywords, image, page_type, path = null }) => {

    const url = path ? (process.env.NEXT_PUBLIC_STOREFRONT_URL + (path === '/' ? '' : path)) : null

    return (
        <Head>
            {title ?
                <title>{title}</title>
                :
                null
            }
            {description ?
                <meta name="description" content={description} />
                :
                null
            }

            {keywords ?
                <meta name="keywords" content={keywords} />
                :
                null
            }

            {url && <meta property="og:url" content={url} />}
            {url && <link rel="canonical" href={url} />}

            {page_type ?
                <meta property="og:type" content={page_type} />
                :
                null
            }

            {title ?
                <meta property="og:title" content={title} />
                :
                null
            }

            {description ?
                <meta property="og:description" content={description} />
                :
                null
            }

            {title ?
                <meta property="twitter:title" content={title} />
                :
                null
            }

            {description ? <meta property="twitter:description" content={description} />
                :
                null
            }

            {image ?
                <meta property="og:image" content={image} />
                :
                null
            }

            {image ?
                <meta property="og:image:url" content={image} />
                :
                null
            }

            {image ?
                <meta property="og:image:secure_url" content={image} />
                :
                null
            }

            {image ?
                <meta name="twitter:image" content={image} />
                :
                null
            }

            {image ?
                <meta property="og:image:type" content={image ? ('image/' + image.split('.').at(-1)) : ''} />
                :
                null
            }
        </Head>
    );
}

export default CustomMetas;
