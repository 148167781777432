import { Box, BoxProps } from '@mui/material'
import React from 'react'

const Center: React.FC<BoxProps> = ( { children, ...props } ) => {
    const csx = props?.sx || {}
    if ( props.sx ) delete props.sx
    return (
        <Box { ...props } sx={ { display: "flex", justifyContent: "center", alignItems: "center", ...csx } } >
            { children }
        </Box>
    )
}

export default Center