export const getComplementaryColor = ( color: string ): string => {
    const isHexColor = ( color: string ): boolean => /^#(?:[0-9A-Fa-f]{3}){1,2}$/.test( color )
    const isRgbaColor = ( color: string ): boolean => /^rgba?\(\s*(\d{1,3}\s*,\s*\d{1,3}\s*,\s*\d{1,3}(?:\s*,\s*(?:0\.\d+|1(?:\.0)?)\s*)?)\)$/.test( color )

    const hexToRgb = ( hex: string ): number[] | null => {
        if ( isHexColor( hex ) )
        {
            if ( hex.length === 4 )
            {
                hex = `#${ hex[ 1 ] }${ hex[ 1 ] }${ hex[ 2 ] }${ hex[ 2 ] }${ hex[ 3 ] }${ hex[ 3 ] }`
            }
            return hex.match( /[A-Fa-f0-9]{2}/g )?.map( segment => parseInt( segment, 16 ) ) || null
        }
        return null
    }

    const rgbaToRgb = ( rgba: string ): number[] | null => {
        if ( isRgbaColor( rgba ) )
        {
            const components = rgba.match( /(\d{1,3})/g )?.map( Number )
            return components ? components.slice( 0, 3 ) : null
        }
        return null
    }

    if ( isHexColor( color ) )
    {
        const rgbColor = hexToRgb( color )
        return rgbColor ? `#${ rgbColor.map( component => ( 255 - component ).toString( 16 ).padStart( 2, '0' ) ).join( '' ) }` : 'inherit'
    } else if ( isRgbaColor( color ) )
    {
        const rgbColor = rgbaToRgb( color )
        return rgbColor ? `rgba(${ rgbColor.map( component => 255 - component ).join( ', ' ) })` : 'inherit'
    } else
    {
        console.error( "Unsupported color format" )
        return 'inherit'
    }
}