import { Grid } from '@mui/material'
import React from 'react'
import useTranslation from '../../hooks/useTranslation'

const TopLeftTags = ({ styles, product, storeData }) => {

    const isNew = product.categories?.filter((item) => item.id === parseInt(storeData?.new_products_category_ids)).length > 0;
    const isSale = product?.gspunna_regular_price > 0 || product.special_price > 0 || product.categories?.filter((item) => item.id === parseInt(storeData?.on_sale_category_ids)).length > 0;
    const isBlackFridayCat = storeData?.black_friday_enabled && isSale

    const { t } = useTranslation()

    return (
        <Grid className={styles.left_labels} display="flex" flexDirection="column" width="max-content" p={1} pt={0} pb="-2px" sx={{ "span": { zIndex: 1, borderRadius: "3px" } }}>
            {isNew ?
                <span className="bg-yellow" style={{ zIndex: 2 }}>
                    {t("New")}
                </span>
                :
                null
            }
            {
                !isBlackFridayCat && isSale ?
                    <span className="bg-blue" style={{ zIndex: 2 }}>
                        {t("Sale")}
                    </span>
                    :
                    null
            }
        </Grid >
    )
}

export default TopLeftTags

