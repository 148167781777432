import { Grid, IconButton, Modal } from '@mui/material'
import React, { useState } from 'react'
import TagIcon from '../icons/tag'
import CloseIcon from '@mui/icons-material/Close';
import Image from 'next/image';

const Energytags = ({ product, styles, metaData }) => {
    const closeEnergyLabel = () => setOpenEnergyLabel(false)
    const [openEnergyLabel, setOpenEnergyLabel] = useState(false);
    const [energyLabel, setEnergyLabel] = useState()
    const handleOpenEnergyLabel1 = () => {
        setOpenEnergyLabel(true)
        setEnergyLabel(product?.energy_efficiency_label_1.url)
    }

    const handleOpenEnergyLabel2 = () => {
        setOpenEnergyLabel(true)
        setEnergyLabel(product?.energy_efficiency_label_2.url)
    }    

    return (
        <Grid onClick={(e) => e.preventDefault()}>
            {
                product?.energy1 ?
                    <Image
                        onClick={(e) => { e.preventDefault(); handleOpenEnergyLabel1() }}
                        src={product?.energy1}
                        alt='energy label2'
                        width={52}
                        height={20}
                        style={{ marginRight: '2px' }}
                    />
                    :
                    null
            }
            {
                product?.energy2 ?
                    <Image
                        onClick={(e) => { e.preventDefault(); handleOpenEnergyLabel2() }}
                        src={product?.energy2}
                        alt='energy label2'
                        width={52}
                        height={20}
                    />
                    :
                    null
            }
            <Modal
                open={openEnergyLabel}
                onClose={closeEnergyLabel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Grid className="energy_label">
                    <img src={energyLabel} alt="" />
                    <IconButton className="close_icon" onClick={closeEnergyLabel} >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Modal>
        </Grid>
    )
}

export default Energytags