import React from 'react'

const Cm60 = (props) => {
    return <svg width="61" height="64" viewBox="0 0 61 64" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props} className={`cm60 ${props.className || ''}`} style={props.style}>
<path d="M31.1953 -0.000893734V1.35236H31.0093C30.2484 1.23814 29.4721 1.40664 28.8271 1.826C28.2178 2.28467 27.8016 2.95424 27.66 3.70365C27.9358 3.47103 28.2578 3.29951 28.6048 3.20037C28.9518 3.10124 29.3158 3.07675 29.6729 3.12851C30.0479 3.15941 30.4108 3.27617 30.7335 3.46977C31.0561 3.66336 31.3299 3.9286 31.5336 4.24495C31.9576 4.94433 32.1138 5.77361 31.9735 6.57932C31.9456 7.003 31.8342 7.41697 31.6454 7.79732C31.4567 8.17766 31.1945 8.51685 30.8739 8.79527C30.5528 9.05565 30.1806 9.24586 29.7815 9.35366C29.3824 9.46146 28.9651 9.48445 28.5565 9.42115C28.1232 9.38075 27.7047 9.24253 27.3326 9.01691C26.9604 8.79129 26.6444 8.48415 26.4082 8.11864C25.8963 7.27182 25.7043 6.26979 25.8669 5.29372V4.76933C25.9636 3.36548 26.5644 2.04386 27.5585 1.04788C28.0457 0.60914 28.6262 0.286774 29.2562 0.105098C29.8862 -0.0765773 30.5493 -0.112819 31.1953 -0.000893734ZM28.9963 4.26186C28.6586 4.22143 28.3165 4.28373 28.0148 4.44065C27.713 4.59756 27.4656 4.8418 27.3047 5.14148V5.61512C27.2002 6.18978 27.2833 6.78282 27.5415 7.30669C27.6406 7.5019 27.7844 7.6709 27.9612 7.79988C28.1381 7.92886 28.3429 8.01416 28.559 8.0488C28.7752 8.08345 28.9964 8.06646 29.2047 7.99922C29.413 7.93197 29.6024 7.81639 29.7575 7.66192C30.0875 7.29628 30.2894 6.83311 30.3326 6.3425C30.3754 5.90571 30.2933 5.46575 30.0958 5.07382C30.0005 4.87284 29.8559 4.69921 29.6755 4.56908C29.4951 4.43896 29.2847 4.35655 29.064 4.32953L28.9963 4.26186Z" fill="#545554"/>
<path d="M39.3822 8.21947C39.0745 9.25561 38.4541 10.1714 37.606 10.8414C37.2279 11.0726 36.7988 11.2074 36.3563 11.2339C35.9139 11.2605 35.4717 11.178 35.0687 10.9936C34.6547 10.8749 34.2748 10.6596 33.9602 10.3656C33.6456 10.0715 33.4052 9.70706 33.2587 9.30208C33.0238 8.27145 33.1246 7.19272 33.5463 6.22342L34.1214 4.70101C34.3991 3.67721 34.9907 2.76618 35.813 2.09599C36.1927 1.86891 36.6214 1.73653 37.0631 1.71003C37.5047 1.68353 37.9462 1.76369 38.3503 1.94375C38.7656 2.06387 39.147 2.27938 39.4642 2.57307C39.7814 2.86676 40.0255 3.23052 40.1772 3.63532C40.4309 4.67727 40.348 5.77281 39.9404 6.76472L39.3822 8.21947ZM38.5195 6.02043C38.7897 5.4097 38.9003 4.74032 38.8409 4.07512C38.8019 3.84522 38.6985 3.63106 38.5429 3.45744C38.3872 3.28381 38.1856 3.15781 37.9613 3.09401C37.7541 3.00545 37.527 2.97441 37.3037 3.00418C37.0804 3.03396 36.8693 3.12343 36.6926 3.26317C36.2295 3.68934 35.8904 4.23297 35.7115 4.83633L34.9672 6.8493C34.6731 7.45975 34.5392 8.13503 34.5781 8.81152C34.6137 9.04404 34.7132 9.2621 34.8655 9.44132C35.0179 9.62055 35.2171 9.7539 35.4408 9.82646C35.645 9.91541 35.8696 9.94667 36.0903 9.91684C36.311 9.88702 36.5193 9.79725 36.6926 9.65731C37.1688 9.1886 37.5181 8.60651 37.7075 7.96574L38.5195 6.02043Z" fill="#545554"/>
<path d="M49.0588 16.3733C48.7517 16.7002 48.3827 16.9627 47.9732 17.1457C47.5638 17.3286 47.122 17.4284 46.6737 17.439C45.7393 17.4059 44.8491 17.033 44.1701 16.3903C43.6272 15.9497 43.2221 15.3626 43.0029 14.6987C42.8189 14.0386 42.8307 13.3393 43.0368 12.6857C43.2501 11.9281 43.6317 11.2284 44.1532 10.6389L44.7283 9.9623C45.2293 9.32871 45.8649 8.81446 46.5891 8.4568C47.2269 8.15735 47.9385 8.05149 48.6359 8.15232C49.3469 8.26886 50.01 8.58573 50.5474 9.06576C51.265 9.63368 51.7471 10.4471 51.9006 11.3494C51.9878 12.2567 51.7279 13.1634 51.1732 13.8867L49.9384 12.821C50.2836 12.3969 50.4639 11.8622 50.4459 11.3156C50.3351 10.85 50.066 10.4374 49.6847 10.1484C49.4582 9.94454 49.1877 9.79575 48.8943 9.71359C48.6009 9.63144 48.2925 9.61814 47.9931 9.67473C47.2259 9.91568 46.5522 10.3884 46.0647 11.028L45.5065 11.6708C44.9367 12.2497 44.5537 12.9863 44.4069 13.7852C44.362 14.1034 44.4026 14.4277 44.5244 14.725C44.6463 15.0223 44.8452 15.2818 45.1005 15.4768C45.4618 15.8487 45.9526 16.0668 46.4707 16.0858C47.013 16.0356 47.5184 15.789 47.8916 15.3922L49.0588 16.3733Z" fill="#545554"/>
<path d="M56.8396 16.1374L52.0694 22.1933L59.6476 20.7723L60.7471 22.5823L52.6952 27.471L51.8495 26.067L54.5052 24.46L58.1421 22.447L50.4793 23.8341L49.8872 22.8361L54.6406 16.6957L51.2574 18.9793L48.6016 20.5863L47.7559 19.1992L55.8077 14.3105L56.8396 16.1374Z" fill="#545554"/>
<path d="M24.9363 62.6715C38.2024 62.6715 48.9566 51.9172 48.9566 38.6512C48.9566 25.3851 38.2024 14.6309 24.9363 14.6309C11.6703 14.6309 0.916016 25.3851 0.916016 38.6512C0.916016 51.9172 11.6703 62.6715 24.9363 62.6715Z" fill="#034EA2"/>
<path d="M25.5959 21.803H28.0995L26.0865 23.3762L26.8646 25.8797L24.8516 24.3404L22.8387 25.8797L23.6337 23.3762L21.5869 21.803H24.0904L24.8516 19.3672L25.5959 21.803Z" fill="#FFF200"/>
<path d="M25.6809 54.0114H28.2013L26.1715 55.5339L26.9496 57.9359L24.9366 56.4642L22.9236 57.9359L23.7187 55.5339L21.6719 54.0114H24.1754L24.9366 51.6602L25.6809 54.0114Z" fill="#FFF200"/>
<path d="M33.6477 51.8786H36.1681L34.1382 53.401L34.9164 55.8031L32.9034 54.3145L30.8904 55.8031L31.6855 53.401L29.6387 51.8786H32.1422L32.9034 49.5273L33.6477 51.8786Z" fill="#FFF200"/>
<path d="M33.6477 23.9516H36.1681L34.1382 25.474L34.9164 27.876L32.9034 26.4043L30.8904 27.876L31.6855 25.474L29.6387 23.9516H32.1422L32.9034 21.6172L33.6477 23.9516Z" fill="#FFF200"/>
<path d="M39.5686 29.8728H42.0721L40.0422 31.3783L40.8373 33.7972L38.8243 32.3086L36.8113 33.7972L37.6064 31.3783L35.5596 29.8728H38.0631L38.8243 27.5215L39.5686 29.8728Z" fill="#FFF200"/>
<path d="M39.5686 46.0427H42.0721L40.0422 47.5651L40.8373 49.984L38.8243 48.4955L36.8113 49.984L37.6064 47.5651L35.5596 46.0427H38.0631L38.8243 43.6914L39.5686 46.0427Z" fill="#FFF200"/>
<path d="M41.7675 37.8396H44.2879L42.275 39.362L43.0531 41.764L41.0401 40.2754L39.0272 41.764L39.8222 39.362L37.7754 37.8396H40.2789L41.0401 35.4883L41.7675 37.8396Z" fill="#FFF200"/>
<path d="M17.4768 23.986H19.9803L17.9504 25.4915L18.7455 27.9105L16.7325 26.4219L14.7195 27.9105L15.4977 25.4915L13.4678 23.986H15.9713L16.7325 21.6348L17.4768 23.986Z" fill="#FFF200"/>
<path d="M11.7258 29.8884H14.2293L12.2164 31.4108L12.9945 33.8128L10.9815 32.3243L8.96856 33.8128L9.7636 31.4108L7.7168 29.8884H10.2203L10.9815 27.5371L11.7258 29.8884Z" fill="#FFF200"/>
<path d="M9.57707 37.8571H12.0975L10.0676 39.3796L10.8627 41.7816L8.84969 40.293L6.83672 41.7816L7.63176 39.3796L5.58496 37.8571H8.08849L8.84969 35.5059L9.57707 37.8571Z" fill="#FFF200"/>
<path d="M11.7258 46.0609H14.2293L12.2164 47.5833L12.9945 50.0023L10.9815 48.5137L8.96856 50.0023L9.7636 47.5833L7.7168 46.0609H10.2203L10.9815 43.7266L11.7258 46.0609Z" fill="#FFF200"/>
<path d="M17.5618 51.8962H20.0653L18.0354 53.4186L18.8304 55.8206L16.8175 54.349L14.7876 55.8206L15.5826 53.4186L13.5527 51.8962H16.0563L16.8175 49.5449L17.5618 51.8962Z" fill="#FFF200"/>
</svg>

}

export default Cm60