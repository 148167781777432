import { Box } from "@mui/material";
import ProductCardGallery from "../product-card-gallery/productCardGallery";
import styles from "../../styles/product-card.module.scss"
import { useRouter } from "next/router";

const ProductCardTop = ({ product }) => {

    const router = useRouter()

    return (
        <Box sx={{ p: "12px", flex: "1 1 auto" }} className="productTop">


            {router?.asPath?.includes('productCompare') || router?.asPath?.includes('customer')
                ?
                <Box
                    key={product.sku + "_"}
                    className={styles.product_img}
                    sx={{
                        backgroundImage: `url(${product?.small_image?.url || product?.image?.url})`,
                        height: "100%"
                    }}
                ></Box>
                :
                <ProductCardGallery product={product} key={product.sku + "_gallery"} />
            }
        </Box >
    );
}

export default ProductCardTop;