"use client"

import React, { ReactNode, useRef, useState } from 'react'
import { Swiper as Swiper1, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Parallax, Autoplay, Scrollbar } from 'swiper/modules'
import { ScrollbarOptions, SwiperModule } from 'swiper/types'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'
import 'swiper/css/scrollbar'
import 'swiper/css'
import { Box } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Center from '../center'
import zIndex from '@mui/material/styles/zIndex'


interface SwiperPropsType {
    breakpoints?: any
    navigation?: boolean,
    parallax?: boolean,
    pagination?: boolean,
    Autoplay?: boolean,
    scrollbar?: boolean | ScrollbarOptions | undefined,
    children: ReactNode,
    sx?: any
    outerNavigation?: boolean
}

const Swiper = (props: SwiperPropsType) => {
    const sRef = useRef<any>()
    const [disablePrev, setDisablePrev] = useState(true)
    const [disableNext, setDisableNext] = useState(false)
    const modules: SwiperModule[] = [
        props.pagination ? Pagination : null,
        props.navigation ? Navigation : null,
        props.parallax ? Parallax : null,
        props.Autoplay ? Autoplay : null,
        props.scrollbar ? Scrollbar : null
    ].filter(item => item !== null) as SwiperModule[]

    const swiperProps: SwiperPropsType = {
        navigation: props.navigation || false,
        parallax: props.parallax || false,
        Autoplay: false,
        ...props,
    }

    const arr = props.children as ReactNode[]

    const goNext = () => {
        let element = sRef?.current?.querySelector('.swiper-button-next')
        if (element) element.click()
    }
    const goPrev = () => {
        let element = sRef?.current?.querySelector('.swiper-button-prev')
        if (element) element.click()
    }

    const buttonStatus = () => {
        setDisablePrev(Boolean(sRef?.current?.querySelector('.swiper-button-prev.swiper-button-disabled')))
        setDisableNext(Boolean(sRef?.current?.querySelector('.swiper-button-next.swiper-button-disabled')))
    }

    console.log(props.outerNavigation && props.navigation);


    return (
        <Box sx={{
            position: "relative", ">*": props.sx,
            ">div .swiper-button-prev:not(.swiper-button-lock),>div .swiper-button-next:not(.swiper-button-lock)": { display: { xs: props.outerNavigation ? "none" : "block" } },
            ">.swiper-button-prev, >.swiper-button-next": { color: "#000 !important", "&:after": { fontWeight: 700 } }
        }}>
            <Swiper1
                ref={sRef}
                style={{
                    width: "100%",
                    height: "100%",
                }}
                autoplay={{
                    delay: 6000,
                    disableOnInteraction: false,
                }}
                loop={true}
                {...swiperProps}
                modules={modules}
                pagination={{
                    enabled: props.pagination || false,
                    clickable: props.pagination || false,
                }}
                onSlideChange={() => buttonStatus()}
            >
                {arr ?
                    arr.map((child: React.ReactNode, i: number) =>
                        <SwiperSlide key={`_${i}`}>
                            {child}
                        </SwiperSlide>
                    )
                    :
                    null
                }
            </Swiper1>
            {
                props.outerNavigation && props.navigation ?
                    <>
                        <Center sx={{
                            display: "flex",
                            width: 30, height: 30,
                            position: "absolute", left: { xs: -8, md: -30 },
                            zIndex: 3,
                            backgroundColor: "#fff",
                            top: "calc(50% - 15px)",
                            border: "1px solid #404040",
                            borderRadius: "50%",
                            opacity: disablePrev ? 0.1 : 1
                        }} tabIndex={0} role="button" aria-label="Previous slide" onClick={() => !disablePrev && goPrev()} >
                            <ChevronLeftIcon style={{ width: "28px", height: "28px" }} />
                        </Center>
                        <Center sx={{
                            display: "flex",
                            width: 30, height: 30,
                            position: "absolute", right: { xs: -8, md: -30 },
                            zIndex: 3,
                            backgroundColor: "#fff",
                            top: "calc(50% - 15px)",
                            border: "1px solid #404040",
                            borderRadius: "50%",
                            opacity: disableNext ? 0.1 : 1
                        }} tabIndex={1} role="button" aria-label="Next slide" onClick={() => !disableNext && goNext()} >
                            <ChevronRightIcon style={{ width: "28px", height: "28px" }} />
                        </Center>
                    </>
                    :
                    null
            }
        </Box>
    )
}

export default Swiper