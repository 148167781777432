/* eslint-disable @next/next/no-img-element */
import { Box } from '@mui/material'
import React, { useState } from 'react'
import styles from "../../styles/product-card.module.scss"
import Image from 'next/image'

const ImageOnHover = ({ images = [] }) => {
    const [activeImage, setActiveImage] = useState(0)
    if (!images.length) return null

    return (
        <Box className={styles.product_img} position="absolute" display="flex" top={0} bottom={0} right={0} left={0}
            sx={{
                width: "100%",
                height: "100%"
            }}
        >
            <Box position="relative" minWidth="100%" height="100%" display="flex">
                <Image fill src={(images?.at(activeImage)?.thumbnail_url || images?.at(activeImage)?.url)} alt='prod_image' style={{ objectFit: "contain", objectPosition: "center", zIndex: 1, position: "absolute", top: 0, right: 0, width: "100%", height: "100%" }} />
                {
                    Array.from(Array(images?.length)).map((_, i) =>
                        <Box className="image-indicator" key={`image-indicator-${i}`} width={`calc((100% / ${images?.length}) - 4px)`} height="100%" zIndex={2} onMouseEnter={() => {
                            console.log(i);
                            setActiveImage(i)
                        }}>
                            <Box position="absolute" width={7} height={7} borderRadius="50%" right={(12 * (images?.length - i))} bottom={4} className={(i === activeImage ? "bg-blue" : "bg-gray")} />
                        </Box>
                    )
                }
            </Box>
        </Box>
    )
}

export default ImageOnHover