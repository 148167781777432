/* eslint-disable @next/next/no-img-element */
import { Box, Grid } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useState } from "react";
import styles from "../../styles/product-card.module.scss"
import cssVars from '../../styles/vars.module.scss'
import useMediaQuery from '@mui/material/useMediaQuery';
import Swiper from "../swiper";
import ImageOnHover from "./image-on-hover";
import Image from "next/image";

const ProductCardGallery = ({ product }) => {
    const theme = useTheme()
    const isXS = useMediaQuery(theme.breakpoints.down('sm'));
    const [hover, setHover] = useState(false)
    const images = product.media_gallery?.length > 0 ? product.media_gallery?.filter(i => (i.__typename === "ProductImage" && (i.url !== product.energy_efficiency_label_1.url && i.url !== product.energy_efficiency_label_2.url))).slice(0, 4) : [product.small_image]


    return (
        <>
            <Box position="relative"
                sx={{
                    display: 'block', height: "100%", position: "relative"
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => { setHover(false) }}
            >
                {
                    images?.length > 1
                        ?
                        isXS
                            ?
                            <Box
                                sx={{
                                    height: "100%",
                                    "--swiper-pagination-bottom": "-4px",
                                    "--swiper-pagination-color": cssVars.blue,
                                    "--swiper-pagination-bullet-inactive-color": cssVars['mid-gray'],
                                    ">div": { height: "100%" }
                                }}
                            >
                                <Swiper
                                    navigation={false}
                                    Autoplay={false}
                                    pagination={images?.length > 1}
                                    scrollbar={false}
                                    loop={true}
                                    key={`${product.sku}_carousel`}

                                >
                                    {
                                        images?.map((image, i) =>
                                            <Grid key={image.thumbnail_url || image.url} position="relative" className={styles.product_img} sx={{ mt: { xs: "0px", sm: 0 }, height: "100%" }} >
                                                <Image fill src={(image.thumbnail_url || image.url)} alt="prod_image" style={{ objectFit: "contain", objectPosition: "center", position: "absolute", top: 0, right: 0, width: "100%", height: "100%" }} />
                                            </Grid>
                                        )
                                    }
                                </Swiper>
                            </Box>
                            :
                            hover
                                ?
                                <ImageOnHover images={images} />
                                :
                                <Box
                                    className={styles.product_img}
                                    position="relative"
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    <Image fill src={(images?.at(0)?.thumbnail_url || images?.at(0)?.url)} alt="prod_image" style={{ objectFit: "contain", objectPosition: "center", position: "absolute", top: 0, right: 0, width: "100%", height: "100%" }} />
                                </Box>

                        :
                        <Box
                            className={styles.product_img}
                            position="relative"
                            sx={{
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <Image fill src={(images?.at(0)?.thumbnail_url || images?.at(0)?.url)} alt="prod_image" style={{ objectFit: "contain", objectPosition: "center", position: "absolute", top: 0, right: 0, width: "100%", height: "100%" }} />
                        </Box>
                }
            </Box>

        </>

    );
}

export default ProductCardGallery;