import { CircularProgress, IconButton } from '@mui/material'
import { useContext, useState } from 'react';
import { CartContext } from '../../Providers/Cartprovider';
import useCss from '../../hooks/useCss';
import BasketIcon from '../icons/basket';


const AddToCartIcon = ({ product, isSale = false }) => {
    const { addToCart, addLoading, cartReady, cartLoading } = useContext(CartContext)
    const [addingToCart, setaddingToCart] = useState(false)
    const { c } = useCss()

    return (
        <IconButton
            className="bg-hover-black"
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                bgcolor: (!isSale ? c("yellow") : c("white")),
                color: "#111", ':hover': { color: "#fff" },
                width: "45px !important",
                height: "45px !important",
                cursor: "pointer",
                '&.Mui-disabled': {
                    color: c('white')
                },
                p: 0,
                m: 0.8,
            }}
            onClick={(e) => {
                e.preventDefault();
                setaddingToCart(true)
                addToCart({
                    quantity: 1, product,
                    onComplete: (data) => {
                        setaddingToCart(false);
                    },
                })
            }}
            disabled={(cartLoading || !cartReady) || addingToCart}
        >
            {addLoading === product.sku ?
                <CircularProgress style={{ width: "unset", height: "unset", color: "white" }} />
                :
                <BasketIcon />
            }
        </IconButton>
    )
}

export default AddToCartIcon
