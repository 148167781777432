import { Grid, Skeleton, Typography } from '@mui/material'
import useAddZeroes from '../../hooks/useAddZeros'
import useTranslation from '../../hooks/useTranslation'
import AddToCartIcon from './addToCartIcon'
import { getComplementaryColor } from '../../helpers/complementaryColor'


const ProductCardFooter = ({ product, styles, fontSize, storeConfig }) => {

    const { z } = useAddZeroes()
    const { t } = useTranslation()
    const isSale = product?.gspunna_regular_price > 0 || product.special_price > 0
    const isBlackFridayCat = storeConfig?.black_friday_enabled && isSale
    const color2 = getComplementaryColor(storeConfig?.black_friday_color)
    console.log(product.stock_color_label);

    return (
        <Grid item xs={2} className={styles.price_box + " " + (!isBlackFridayCat && isSale ? styles.offer : "")} sx={storeConfig?.black_friday_enabled && isBlackFridayCat ? {
            background: storeConfig.black_friday_backend_color1,
            color: storeConfig.black_friday_color,
            "button": {
                background: storeConfig?.black_friday_color,
                color: color2 + " !important",
                "&:hover": {
                    background: color2,
                    color: storeConfig?.black_friday_color + " !important",
                }
            }
        } : {}} >
            {
                product?.price_range
                    ?
                    <Grid className={styles.prices
                    } px={0.8} >
                        <Typography variant='span' sx={{ fontWeight: "700", fontSize: fontSize || { xs: "26px", md: "18px", sm: "26px", lg: "26px" } }} className={styles.curr_price} >
                            {product?.price_range?.maximum_price?.final_price.currency}
                            <b>
                                {z(product?.price_range?.maximum_price.final_price.value)}
                            </b>
                        </Typography>
                        {
                            product?.gspunna_regular_price > 0 ?
                                <Typography variant='span' sx={{ fontStyle: "italic", fontSize: "16px" }} >
                                    {t("instead of") + " " + product?.price_range?.maximum_price?.regular_price.currency + " " + z(product?.gspunna_regular_price) + " "}
                                </Typography>
                                :
                                null
                        }
                    </Grid >
                    :
                    <Skeleton variant='rectangular' width={150} height={30} />
            }

            {
                product?.stock_color_label !== "red" ?
                    <AddToCartIcon isSale={isSale} product={product} />
                    :
                    null
            }
        </Grid >
    )
}

export default ProductCardFooter