import React from 'react'
import styles from '../../styles/tag.module.scss'
const TagIcon = (props) => {

    if (props && props.children && Array.isArray(props.children) && props.children.at(1) === "_") {
        var children = props.children.at(0)
        var color = {
            "A": '#00963f',
            "B": '#54ac34',
            "C": '#c9d300',
            "D": '#feed01',
            "E": '#fdb900',
            "F": "#ed660c"
        }[children] || '#e40613'
    }
    else {
        var children = props.children
        var color = {
            "A+++": '#00a651',
            "A++": '#4db748',
            "A+": '#ccd302',
            "A": '#f7d100',
            "B": '#f9bb08',
            "C": '#ec6b10',
            "D": '#df071e',
        }[children] || '#df071e'
    }

    var height = parseInt(props.style?.height || props.height || "22px")
    var width = parseInt(props.style?.width || props.width || "50px")
    return (
        <div className={styles.e_label + " " + (props.className || '')} style={{ ...props.style, position: "relative", display: "inline-block", width: width + "px", height: height + "px" }} onClick={(e) => { e.preventDefault(); props.openEnergyLabel() }}>
            <svg viewBox={`0 0 ${width} ${height}`} style={{ verticalAlign: "sub" }}>
                <polygon points={`0,0 ${width / 1.2},0 ${width},${height / 2} ${width / 1.2},${height} 0,${height}`} fill={color} />
            </svg>
            {children && <span>{children.charAt(0)}<sup>{children.substring(1)}</sup></span>}
        </div>
    )
}

export default TagIcon