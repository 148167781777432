import styles from "../../styles/product-card.module.scss"
import { Box, CircularProgress } from '@mui/material';
import Link from "next/link";
import ProductCardTop from "./productCardTop";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useContext, useState } from "react";
import { CompareListContext } from "../../Providers/CompareListProvider";
import useCss from "../../hooks/useCss";
import ProductCardFooter from "./productCardFooter";
import ProductCardMiddle from "./productCardMiddle";
import { WishListContext } from "../../Providers/WishListContext";
import CancelIcon from '@mui/icons-material/Cancel';
import { useCatStore } from "../../stores/catStore";
import ProductCardNav from "./productCardNav";
import { useQuery } from "@apollo/client";
import { useCacheStore } from "../../stores/cacheStore";

const ProductCard = ({ product, className, isMoving = false, metaData, storeData, inComparePage = 0, InfavPage = 0, category_path, variables, prefetch = true }) => {

    const { addToCompareList, addRemoveLoading } = useContext(CompareListContext)
    const { handleAddProductToWishList } = useContext(WishListContext)
    const { c } = useCss()
    const setCatPath = useCatStore(state => state.setCatPath)
    const handleCat = () => {
        if (category_path) {
            setCatPath(category_path)
        }
    }

    const storeConfigCached = useCacheStore(state => state.storeConfig)
    const storeConfig = storeData || storeConfigCached

    return (
        <div className="position-relative" onClick={handleCat} >
            <div className="styles.productBefore" onClick={addRemoveLoading === product.id ? () => { } : (e) => { e.preventDefault(); addToCompareList(product.id); }} style={{ width: "20px", display: Boolean(inComparePage) ? "block" : "none" }} >
                {addRemoveLoading === product.id ?
                    <CircularProgress style={{ width: "20px", height: "20px", color: "#111 !important" }} />
                    :
                    <DeleteOutlineIcon sx={{ color: c('dark-gray'), ':hover': { color: c('red'), cursor: "pointer" } }} />
                }
            </div>
            <CancelIcon onClick={(e) => { e.preventDefault(); handleAddProductToWishList(product.sku) }}
                sx={{ color: c('red'), width: "40px !important", height: "40px !important", bgcolor: "unset", position: "absolute", right: "-18px", top: "-18px", cursor: "pointer", zIndex: "1", display: InfavPage === 0 ? "none" : "block" }} />
            <Link prefetch={false} href={'/' + product.canonical_url} className={styles.product_link} onClick={(e) => handleCat()} >
                <Box className={styles.product + " " + className}
                    sx={{
                        paddingBottom: inComparePage === 0 ? "10px" : "none",
                        position: "relative",
                        mt: Boolean(inComparePage) ? 0.5 : 0,
                        pb: "157%",
                        '&:hover': { boxShadow: inComparePage === 0 ? "0px 8px 8px -4px #2C2C2C25, 0px 10px 24px -4px #2C2C2C25" : "none" },
                        boxShadow: inComparePage === 0 ? "0px 8px 8px -4px #2C2C2C0A,0px 10px 24px -4px #2C2C2C1A" : "none"
                        , border: { border: inComparePage === 0 ? `1px solid ${c('gray')}` : "none" }, borderLeft: `1px solid ${c('gray')}`,
                        borderRadius: inComparePage === 0 ? c('borderRadius') : 0, height: { md: "400px", xs: "460px" }
                    }}
                >
                    <div className={styles.innerDiv} sx={{ position: "absolute", left: 0, top: 0, bottom: 0, right: 0, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "stretch" }}>
                        <ProductCardNav product={product} storeData={storeConfig} inComparePage={inComparePage} />
                        <ProductCardTop product={product} />
                        <ProductCardMiddle product={product} inComparePage={inComparePage} metaData={metaData} />
                        <ProductCardFooter styles={styles} product={product} fontSize={InfavPage ? { xs: "20px", md: "16px", sm: "20px", lg: "20px" } : null} storeConfig={storeConfig} />
                    </div>
                </Box >
            </Link>
        </div >
    )
}

export default ProductCard