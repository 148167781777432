import { Box, Grid } from '@mui/material'
import React from 'react'
import TopLeftTags from './topLeftTags'
import Stockstate from '../stockStatus/stockstate'
import Cm60 from '../icons/cm60'
import NoFrost from '../icons/noFrost'
import styles from "../../styles/product-card.module.scss"

const ProductCardNav = ({ product, storeData, inComparePage }) => {

    const isNoFrost = (product.isBoxalino ? (product?.no_frost === "Ja" || product?.no_frost === "Oui") : product?.no_frost == 1971)
    const isEuNorm60 = (product.isBoxalino ? (product?.eu_norm_60 === "Ja" || product?.eu_norm_60 === "Oui") : product?.eu_norm_60 == 2104)

    return (
        <Grid container className={styles.labels} columns={3}  >
            <Grid item xs={1} sx={{ mt: inComparePage === 0 ? 0 : 1 }}>
                <TopLeftTags product={product} styles={styles} storeData={storeData} />
            </Grid >
            <Grid item xs={2} sx={{ px: 0.6, pt: inComparePage === 0 ? 0.8 : 1.5, position: "relative", mt: "-1px" }}>
                <Stockstate product={product} />
            </Grid>
            <Box position="absolute" right={12} top={54} zIndex={2}>
                {isNoFrost ? <Box zIndex={2} mb={1.5}><NoFrost width="38" height="38" /></Box> : null}
                {isEuNorm60 ? <Box zIndex={2} ><Cm60 width="38" height="38" /></Box> : null}
            </Box>
        </Grid >
    )
}

export default ProductCardNav