import { Box, Grid, Rating, Typography } from "@mui/material";
import FavoriteIconFilled from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import CompareIcon from "../icons/compare";
import Energytags from "./energytags";
import Image from "next/image";
import styles from "../../styles/product-card.module.scss"
import { CompareListContext } from "../../Providers/CompareListProvider";
import { useContext } from "react";
import { WishListContext } from "../../Providers/WishListContext";
import useCss from "../../hooks/useCss";
import { useRouter } from "next/router";
import { strEncode } from "../../helpers/SearchParams";

const ProductCardMiddle = ({ product, inComparePage, metaData }) => {

    const router = useRouter()
    const { c } = useCss()
    const { isFav, handleAddProductToWishList, wishListReady } = useContext(WishListContext)
    const { addToCompareList, isCompared, compareReady } = useContext(CompareListContext)


    const handleAddProductToCompareList = (e) => {
        e.preventDefault();
        addToCompareList(product.id)
    }

    const addProductToWishlist = (e) => {
        handleAddProductToWishList(product.sku)
    }
    const filterByBrand = (e) => {
        e.preventDefault();
        router.push(`/catalogsearch/result/${strEncode('{"q":"' + product?.brand_label + '"}')}`,)
    }

    return (
        <Grid container className={styles.middle} columns={2} sx={{ width: "100%", position: "relative", px: "12px" }} >
            {
                product?.cash_back_label ?
                    <Grid item xs={2} display="flex" alignItems="center">
                        <Typography variant="span" sx={{ background: "#FF00FF", color: "white", mt: { xs: -2, lg: -4 }, fontSize: { xs: 10, lg: 12 }, p: "1px 6px 1px 6px", zIndex: 2, borderRadius: "2px" }}
                        >
                            {product?.cash_back_label}
                        </Typography>
                    </Grid>
                    :
                    null
            }
            <Grid item xs={2} display="flex" alignItems="center">
                <Typography variant="span" sx={{ ":hover": { color: c("blue") } }} style={{ lineHeight: { md: "40px", xs: "unset" } }} onClick={(e) => filterByBrand(e)} >{product?.brand_label}</Typography>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center", height: "42px" }}>
                <Box className={styles.product_link} >
                    <Typography className={styles.name} variant="p">{product.name}</Typography>
                </Box>
            </Grid>
            <Grid item xs={2} mb="5px" mt="10px">
                <Rating name="half-rating-read" defaultValue={product?.rating_summary / 20} precision={0.5} size="small" readOnly />
            </Grid>
            <Grid container columns={2} item xs={2} className={styles.icons} sx={{ display: "flex", justifyContent: "space-between", pb: 1 }} >
                <Grid display="flex" >
                    <Grid className="f-hover-blue " sx={{ width: "25px" }}
                        onClick={(e) => {
                            e.preventDefault();
                            wishListReady ?
                                addProductToWishlist(e)
                                :
                                router.push('/customer/login.html');
                        }}
                    >
                        {isFav(product?.sku)
                            ?
                            <FavoriteIconFilled style={{ color: c('blue') }} />
                            :
                            <FavoriteBorderOutlinedIcon />
                        }
                    </Grid>
                    <Grid className="f-hover-blue " sx={{ pl: 1, color: isCompared(product.id) ? c('blue') : "", display: inComparePage === 0 ? "block" : "none" }} onClick={(e) => { compareReady ? handleAddProductToCompareList(e) : null }} >
                        <CompareIcon />
                    </Grid>
                </Grid>
                <Energytags product={product} styles={styles} metaData={metaData} />
            </Grid>

        </Grid >
    );
}

export default ProductCardMiddle;